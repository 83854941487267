.container_fluid{
    background-color: #fff;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;
    box-shadow: 0px 0px 2px #888;
}

.noScrollCover{
    display: grid;
    width: 100%;
    grid-template-columns: 80px 120px 100px 1fr 1fr;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 13px;
    /* overflow-x: auto; */
    grid-gap: 10px;
}

.main_container_fluid{
    display: grid;
    grid-template-columns: 80px 120px 100px 1fr 1fr 80px;
    margin-bottom: 10px;
    grid-gap: 10px;
}

.Make_Transaction{
    text-align: center;
    border-radius: 80px;
    width: 100%;
    background-color: #024731;
    padding: 10px 0px;
    cursor: pointer;
    margin-bottom: 30px;
    color: #fff;
}


@media(max-width: 870px){
    .noScrollCover{
        width: 800px;
        /* overflow: hidden; */
        /* overflow-x: scroll; */
    }
    .main_container_fluid{
        width: 800px;
    }
    .container_fluid{
        overflow: hidden;
        overflow-x: scroll;
    }
}