.cardCover{
    position: relative;
    width: 100%;
    max-width: 100%;
    height: max-content;
    color: #222;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px #555;
    -moz-box-shadow: 0px 0px 15px #555;
    background-color: #fff;
    border-radius: 2px;
    font-family: Rajdhani;
    margin: auto;
    box-shadow: .4px .4px 2px #555;   
}

.CardHeader {
    padding: .1em 1em 1em 1em ;
    /* background-color: #f4f4f4; */
    margin-top: 14px;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px solid #222;
    justify-content: space-between;
}
.CardHeader div{
    color: #333;
}

.backdropCover{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    z-index: 500;
    overflow: hidden;
    height: 100vh !important;
    background-color: #00000055;
}
.modalCover{
    position: absolute;
    width: 700px;
    max-width: 90%;
    height: max-content;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    color: #222;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px #555;
    -moz-box-shadow: 0px 0px 15px #555;
    background-color: #fff;
    border-radius: 5px;
    z-index: 2000;
}

.CardHeader svg{
    width: 20px;
    fill: #222;
}